import React from 'react'
import { Col, Row } from 'antd'
import Container from '../Container/ContainerComponent'
import { bikesCategories } from '../../Utilities/AppConstants'
import { navigate } from 'hookrouter'
import { useMediaPredicate } from 'react-media-hook'


export default function FooterInformationComponent() {
  const isSmallScreenForMobile = useMediaPredicate('(max-width: 800px)')
  console.log(isSmallScreenForMobile);
  function navigateToBikesCategories(category) {
    navigate(`/products/${category}`);
    window.scrollTo(0,0);
  }
  return(
    <Row type="flex" justify="center" className={'footerStyle'}>
    <Container>
      {isSmallScreenForMobile ? <Row
        type="flex"
        justify="space-between"
        style={{color:'white',marginBottom:'15px',marginTop:'15px'}}>
        <Col style={{marginBottom:'10px'}} xs={24} sm={24} md={12} lg={6} span={8}>
          <h2 style={{color:'white'}}>Onze fietsenwinkels</h2>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-s-gravenwezel/'}>‘s-Gravenwezel</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-kapellen/'}>Kapellen</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-sint-job-t-goor/'}>Sint-Job-in-‘t-Goor</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-belsele/'}>Belsele (nabij Sint-Niklaas)</a><br/>
          {/*<a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-lokeren/'}>Lokeren</a><br/>*/}
          {/*<a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-in-merksem/'}>Merksem</a><br/>*/}
        </Col>
        <Col style={{marginBottom:'10px'}} xs={24} sm={24} md={12} lg={6} span={8}>
          <h2 style={{color:'white'}}>Fietsen Mintjens</h2>
          <span>Fietsen Mintjens BVBA</span><br/>
          <span>Kerkstraat 21 2970 Schilde</span><br/>
          <a style={{color:'white'}} href='tel:+036588245'>03-658 82 45</a><br/>
          <a  style={{color:'white'}} href='mailto: info@fietsenmintjens.be'>info@fietsenmintjens.be</a>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} span={8}>
          <h2 style={{color:'white'}}>Producten</h2>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.eBike.id)}}>{bikesCategories.eBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.mountainBike.id)}}>{bikesCategories.mountainBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.raceBike.id)}}>{bikesCategories.raceBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.cityBike.id)}}>{bikesCategories.cityBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.vouwBike.id)}}>{bikesCategories.vouwBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.kidsBike.id)}}>{bikesCategories.kidsBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.accessoires.id)}}>{bikesCategories.accessoires.name}</a><br/>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} span={8}>
          <h2 style={{color:'white'}}>Nuttige info</h2>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/onze-fietsenwinkels/'}>Onze fietsenwinkels</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/onderhoud-herstellingen/'}>Onderhoud & herstellingen</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/over-ons/'}>Over ons</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/blog/'}>Blog</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/contact/'}>Contact</a><br/>
        </Col>
      </Row>: <Row
        type="flex"
        justify="space-between"
        style={{color:'white',marginBottom:'15px',marginTop:'15px'}}>
        <Col xs={24} sm={24} md={12} lg={6} span={8}>
          <h2 style={{color:'white'}}>Fietsen Mintjens</h2>
          <span>Fietsen Mintjens BVBA</span><br/>
          <span>Kerkstraat 21 2970 Schilde</span><br/>
          <a style={{color:'white'}} href='tel:+036588245'>03-658 82 45</a><br/>
          <a  style={{color:'white'}} href='mailto: info@fietsenmintjens.be'>info@fietsenmintjens.be</a>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} span={8}>
          <h2 style={{color:'white'}}>Producten</h2>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.eBike.id)}}>{bikesCategories.eBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.mountainBike.id)}}>{bikesCategories.mountainBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.raceBike.id)}}>{bikesCategories.raceBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.cityBike.id)}}>{bikesCategories.cityBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.vouwBike.id)}}>{bikesCategories.vouwBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.kidsBike.id)}}>{bikesCategories.kidsBike.name}</a><br/>
          <a style={{color:'white'}} onClick={()=>{navigateToBikesCategories(bikesCategories.accessoires.id)}}>{bikesCategories.accessoires.name}</a><br/>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} span={8}>
          <h2 style={{color:'white'}}>Onze fietsenwinkels</h2>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-s-gravenwezel/'}>‘s-Gravenwezel</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-kapellen/'}>Kapellen</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-sint-job-t-goor/'}>Sint-Job-in-‘t-Goor</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-belsele/'}>Belsele (nabij Sint-Niklaas)</a><br/>
          {/*<a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-lokeren/'}>Lokeren</a><br/>*/}
          {/*<a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/fietsenwinkel-mintjens-in-merksem/'}>Merksem</a><br/>*/}
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} span={8}>
          <h2 style={{color:'white'}}>Nuttige info</h2>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/onze-fietsenwinkels/'}>Onze fietsenwinkels</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/onderhoud-herstellingen/'}>Onderhoud & herstellingen</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/over-ons/'}>Over ons</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/blog/'}>Blog</a><br/>
          <a style={{color:'white'}} target={'_blank'} href={'https://www.fietsenmintjens.be/contact/'}>Contact</a><br/>
        </Col>
      </Row>}

    </Container>
  </Row>)
}
