import React from 'react'

// Labels Naming Section
export const accessories = 'Accessoires'

export const bikesCategories = {
  eBike: {id:'E-Bikes',name:'E-Bikes'},
  raceBike: {id:'Racefietsen',name:'Racefietsen'},
  cityBike: {id:'Stadsfietsen',name:'Stads- / Vrijetijdsfietsen'},
  vouwBike: {id:'Vouwfiets',name:'Vouwfiets'},
  mountainBike: {id:'Mountainbikes',name:'Mountainbikes'},
  kidsBike:{id:'Kinderfietsen',name:'Kinderfietsen'},
  accessoires: {id:'Accessoires',name:'Accessoires'},
}

export const expertEmails = {
  eBike: 'rik@purplepanda.be',
  raceBike: 'rik@purplepanda.be',
  cityBike: 'rik@purplepanda.be',
  vouwBike: 'rik@purplepanda.be',
  mountainBike: 'rik@purplepanda.be',
  accessoires: 'rik@purplepanda.be',
}

export const filterOptions = {
  Type: {
    name: 'type',
    title: 'Type',
    options: [
      'Pompen',
      'Sloten',
      'Helmen',
      'Tassen',
      'Hand-muts-voet',
      'Regenbroeken',
      'Jassen',
      'Battcovers',
      'Kind',
      'Pedalen',
      'Gps',
    ],
  },
  Speed: {
    name: 'speed',
    title: 'Snelheid',
    options: ['45 km/u', '25 km/u'],
  },
  Brand: {
    name: 'brand',
    title: 'Merk',
    'E-Bikes':['Scott', 'Koga', 'Bizobike', 'Gazelle','Bosch','Das.O. E-Bike'],
    'Racefietsen':['Scott', 'Koga', 'Bizobike', 'Gazelle'],
    'Stadsfietsen':['Scott', 'Koga', 'Bizobike', 'Gazelle','Oxford'],
    'Vouwfiets':['Scott', 'Koga', 'Bizobike', 'Gazelle'],
    'Mountainbikes':['Scott', 'Koga', 'Bizobike', 'Gazelle'],
    'Kinderfietsen':['Scott', 'Koga', 'Bizobike', 'Gazelle'],
    'Accessoires':['Scott', 'Koga', 'Bizobike', 'Gazelle'],
  },
  Price: {
    name: 'price',
    title: 'Prijs',
    options: ['minPrice', 'maxPrice'],
  },
  Usage: {
    name: 'usage',
    title: 'Gebruik',
    options: ['Woon-werk', 'Hobby', 'Pro', 'Vrije tijd'],
  },
}

//End Labels Naming Section

// Env Constants
export const frontEndEnvURL = process.env.PUBLIC_URL
export const backEndEnvURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:2000'
    : 'https://fietsenmintjens-backend.herokuapp.com/'

//End Env Constants

// Random App Constants
export const formTypes = ["bestelnu","proefrit"]


// End Random App Constants


//Images Section
export const eBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/eBike.png`}
      alt={'E-Bike  Not Found'}
    />
  )
}

export const raceBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/raceBike.png`}
      alt={'Sport Bike  Not Found'}
    />
  )
}

export const cityBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/cityBike.png`}
      alt={'City Bike  Not Found'}
    />
  )
}

export const mountainBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/mountainBike.png`}
      alt={'Mountain Bike Image  Not Found'}
    />
  )
}

export const vouwBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/vouwBike.png`}
      alt={'Vouw Bike Image  Not Found'}
    />
  )
}
export const kidsBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/kidsBike.jpg`}
      alt={'Kids Image Bike  Not Found'}
    />
  )
}
export const accessoriesImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/accessories.png`}
      alt={'Accessories Image  Not Found'}
    />
  )
}
export const gazelleImage = () => {
  return (
    <img
      style={{ width: '160px', height: '72px' }}
      src={`${frontEndEnvURL}/icons/HomePage/Gazelle.png`}
      alt={'Gazelle Image  not found'}
    />
  )
}
export const scottImage = () => {
  return (
    <img
      style={{ width: '160px', height: '72px' }}
      src={`${frontEndEnvURL}/icons/HomePage/Scott.png`}
      alt={'Scott Image  not found'}
    />
  )
}
export const bizoBikeImage = () => {
  return (
    <img
      style={{ width: '160px', height: '72px' }}
      src={`${frontEndEnvURL}/icons/HomePage/bizobike.png`}
      alt={'Bizo Bike Image  not found'}
    />
  )
}
export const kogaImage = () => {
  return (
    <img
      style={{ width: '160px', height: '72px' }}
      src={`${frontEndEnvURL}/icons/HomePage/koga.png`}
      alt={'Koga Image  not found'}
    />
  )
}
//End Images Section
