import React, { useEffect, useState } from 'react'
import { navigate, useQueryParams } from 'hookrouter'
import { Button, Col, Descriptions, Icon, List, Row } from 'antd'
import _ from 'lodash'
import * as appConstants from '../Utilities/AppConstants'
import * as FietsenMintjensAPI from '../../api/FietsenMintjensAPI'

import Container from '../Common/Container/ContainerComponent'
import ImageButtonComponent from '../Utilities/ImageButtonComponent/ImageButtonComponent'
import BikeListComponent from '../Utilities/BikeComponent/BikeListComponent'

import * as HomeStyle from './Home.module.scss'
import './HomeStyle.css'
import { useMediaPredicate } from 'react-media-hook'
import * as MenuBarDropDownStyle from '../Common/Header/MenuBarDropDown.module.scss'
import { bikesCategories, frontEndEnvURL } from '../Utilities/AppConstants'
import ReactGA from 'react-ga'
import ReactPlayer from 'react-player'
import VideoTextComponent from '../Utilities/VideoTextComponent/VideoTextComponent'


export default function Home(props) {
  //props.googleAnalytics.pageview('/');
  const [productsData, setProductsData] = useState()
  const isSmallScreenForMobile = useMediaPredicate('(max-width: 800px)')
  const [isLoading,setLoading] = useState(true);
  const onDropDownMenuItemClick = (header, text) => {
    setQueryParams({ key: header, value: text }, true)
    navigate(`/products/${bikesCategories.eBike.id}`, false)
  }
  const data = [
    {
      header: 'Populaire E-bikes',
      filter: 'brand',
      data: [
        { text: 'Scott' },
        { text: 'Gazelle' },
        { text: 'Bizobike' },
        { text: 'Koga' },
        {text:'Bosch'}
      ],
    },
    {
      header: 'Snelheid',
      filter: 'speed',
      data: [{ text: '25 km/u' }, { text: '45 km/u' }],
    },
    {
      header: 'Gebruik',
      filter: 'usage',
      data: [
        { text: 'Woon-werk' },
        { text: 'Hobby' },
        { text: 'Pro' },
        { text: 'Vrije tijd' },
      ],
    },
  ];
  useEffect(() => {
    FietsenMintjensAPI.getProducts()
      .then(res => {
        if (!_.isEqual(productsData, res.data)) {
          setProductsData(res.data)
        }
        setLoading(false);
    //    console.log('bikeDataList-----', res.data)
      })
      .catch(err => console.log(err))
  }, [productsData])

  const [, setQueryParams] = useQueryParams()

  const gridListStyle = {
    gutter: 16,
    xs: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2,
  }

  const bikeCompGridStyle = {
    gutter: 16,
    xs: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 3,
    xxl: 3,
  }

  const handleMoreInfoBtnClick = id => {
    const selectedBikeData = productsData.find(item => item._id === id)
    let productCategory
    if (selectedBikeData.categories[0] === 'accessoires') {
      productCategory = appConstants.bikesCategories.accessoires.id
    } else if (selectedBikeData.categories[0] === 'fietsen') {
      productCategory =
        selectedBikeData.categories[1] === 'e-bikes'
          ? selectedBikeData.categories[1].charAt(0).toUpperCase() +
            '-' +
            selectedBikeData.categories[1].charAt(2).toUpperCase() +
            selectedBikeData.categories[1].slice(3)
          : selectedBikeData.categories[1].charAt(0).toUpperCase() +
            selectedBikeData.categories[1].slice(1)
    }
    navigate(`/products/${productCategory}/${id}`)
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
      })
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0)
    }
  }

  const navigateToProductScreenByCategory = category => {
    setQueryParams({}, true)
    navigate(`/products/${category}`)
  }

  const onBrandItemClick = text => {
    setQueryParams({ key: 'brand', value: text }, true)
    navigate(`/products/${appConstants.bikesCategories.eBike.id}`, false)
  }

  return (
    <Container>
      <Row
        gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 }, 20]}
        className={HomeStyle.mainViewContainer}
      >
        <Col xs={24} sm={24} md={24} lg={6} xl={5} className={'bikeCategories'}>
          <div style={{ backgroundColor: 'white' }}>
            <Descriptions
              column={{ xs: 1, sm: 2, md: 3, lg: 1 }}
              title={'Categorieën'}
              colon={false}
            >
              <Descriptions.Item
                label={
                  <Icon
                    className={HomeStyle.categoryIcon}
                    component={appConstants.eBikeImage}
                  />
                }
              >
                <Button
                  onClick={() =>
                    navigateToProductScreenByCategory(
                      appConstants.bikesCategories.eBike.id
                    )
                  }
                  type={'link'}
                >
                  {appConstants.bikesCategories.eBike.name}
                </Button>
              </Descriptions.Item>

              <Descriptions.Item
                label={
                  <Icon
                    className={HomeStyle.categoryIcon}
                    component={appConstants.cityBikeImage}
                  />
                }
              >
                <Button
                  onClick={() =>
                    navigateToProductScreenByCategory(
                      appConstants.bikesCategories.cityBike.id
                    )
                  }
                  type={'link'}
                >
                 { appConstants.bikesCategories.cityBike.name}
                </Button>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Icon
                    className={HomeStyle.categoryIcon}
                    component={appConstants.mountainBikeImage}
                  />
                }
              >
                <Button
                  onClick={() =>
                    navigateToProductScreenByCategory(
                      appConstants.bikesCategories.mountainBike.id
                    )
                  }
                  type={'link'}
                >
                  {appConstants.bikesCategories.mountainBike.name}
                </Button>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Icon
                    className={HomeStyle.categoryIcon}
                    component={appConstants.raceBikeImage}
                  />
                }
              >
                <Button
                  onClick={() =>
                    navigateToProductScreenByCategory(
                      appConstants.bikesCategories.raceBike.id
                    )
                  }
                  type={'link'}
                >
                  {appConstants.bikesCategories.raceBike.name}
                </Button>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Icon
                    className={HomeStyle.categoryIcon}
                    component={appConstants.vouwBikeImage}
                  />
                }
              >
                <Button
                  onClick={() =>
                    navigateToProductScreenByCategory(
                      appConstants.bikesCategories.vouwBike.id
                    )
                  }
                  type={'link'}
                >
                  {appConstants.bikesCategories.vouwBike.name}
                </Button>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Icon
                    className={HomeStyle.categoryIcon}
                    component={appConstants.kidsBikeImage}
                  />
                }
              >
                <Button
                  onClick={() =>
                    navigateToProductScreenByCategory(
                      appConstants.bikesCategories.kidsBike.id
                    )
                  }
                  type={'link'}
                >
                  {appConstants.bikesCategories.kidsBike.name}
                </Button>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Icon
                    className={HomeStyle.categoryIcon}
                    component={appConstants.accessoriesImage}
                  />
                }
              >
                <Button
                  onClick={() =>
                    navigateToProductScreenByCategory(
                      appConstants.bikesCategories.accessoires.id
                    )
                  }
                  type={'link'}
                >
                  {appConstants.bikesCategories.accessoires.name}
                </Button>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={{ span: 18, offset: 1 }}>
          <VideoTextComponent headerText={`Welkom bij Fietsen Mintjens Online.`} bodyText={'Doorblader makkelijk ons aanbod en bestel je droomfiets online. '} videoUrl={'https://fietsenmintjens.s3.eu-central-1.amazonaws.com/Videos/home.mp4'}/>
          {/*<Row gutter={[{ xs: 12, sm: 16, md: 24 }, 20]}>
            <Col span={24}>
              <Row
                gutter={[{ xs: 12, sm: 16, md: 24 }, 20]}
                type="flex"
                justify="center"
              >
                <Col xl={10} style={{ flex: 1 }}>
                  <img
                    src={`${appConstants.frontEndEnvURL}/icons/HomePage/headerBike.png`}
                    width={'100%'}
                    height={'240px'}
                    style={{ minWidth: 220 ,objectFit:'cover'}}
                    alt="Header Bike  Not Found"
                  />
                </Col>
                <Col  className={`${isSmallScreenForMobile ? 'ant-col-xs-24':''}`} >
                  <ImageButtonComponent
                    maxWidth={'100%'}
                    height={'239px'}
                    headerText={'“HUIDIGE PROMOTIE”'}
                    descriptionText={'Lopende deals'}
                    buttonText={'Vind jouw winkel'}
                    imgSrc={'icons/HomePage/currentPromotionImage.png'}
                    buttonTextColor={'#0BB143'}
                  />
                </Col>
              </Row>
            </Col>
          </Row>*/}{/*Corona Remove*/}
          <Row gutter={[{ xs: 12, sm: 16, md: 24 }, 20]}>
            <Col span={24}>
              <Row
                style={{
                  backgroundColor: 'white',
                  borderBottom: '3px solid red',
                  padding: 20,
                }}
              >
                <Col
                  className={'bikeCategories'}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={13}
                  xl={10}
                >
                  <List
                    header={'Populairste merken'}
                    grid={gridListStyle}
                    className={'popularBrands commonHeader'}
                    dataSource={[
                      { image: appConstants.gazelleImage, brand: 'Gazelle' },
                      { image: appConstants.scottImage, brand: 'Scott' },
                      { image: appConstants.bizoBikeImage, brand: 'Bizobike' },
                      { image: appConstants.kogaImage, brand: 'Koga' },
                    ]}
                    renderItem={item => (
                      <List.Item>
                        <Icon
                          component={item.image}
                          onClick={() => onBrandItemClick(item.brand)}
                        />
                      </List.Item>
                    )}
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={11}
                  xl={{ span: 13, offset: 1 }}
                >
                  {/*<List
                    header={'Alle merken'}
                    grid={{
                      gutter: 16,
                      xs: 2,
                      sm: 2,
                      md: 3,
                      lg: 3,
                      xl: 3,
                      xxl: 3,
                    }}
                    className={'commonHeader'}
                    dataSource={[
                      { text: 'Scott' },
                      { text: 'Gazelle' },
                      { text: 'Koga' },
                      { text: 'Stromer' },
                      { text: 'Scott' },
                      { text: 'Scott' },
                      { text: 'Scott' },
                      { text: 'Scott' },
                      { text: 'Scott' },
                      { text: 'Scott' },
                      { text: 'Scott' },
                      { text: 'Scott' },
                    ]}
                    renderItem={item => (
                      <List.Item>
                        <Button
                          onClick={() => onBrandItemClick(item.text)}
                          type={'link'}
                          style={{ color: 'rgba(0, 0, 0, 0.65)' }}
                        >
                          {item.text}
                        </Button>
                      </List.Item>
                    )}
                  />*/} {/*Corona Remove*/}
                  {data.map((listItem, index) => {
                    return (
                      <Col key={`${listItem.header}-${index}`} xs={24} sm={data.length === 3 ? 8 : 12} lg={data.length === 3 ? 8 : 6}>
                        <List
                          header={listItem.header}
                          className={`commonHeader ${MenuBarDropDownStyle.listItems}`}
                          dataSource={listItem.data}
                          renderItem={item => (
                            <List.Item>
                              <Button type="link" onClick={() => onDropDownMenuItemClick(listItem.filter, item.text)} style={{ paddingLeft:0,color: 'rgba(0, 0, 0, 0.65)' }}>
                                {item.text}
                              </Button>
                            </List.Item>
                          )}
                        />
                      </Col>
                    )
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[{ xs: 12, sm: 16, md: 24 }, 20]}>
            <Col span={24}>
              <BikeListComponent
                gridStyle={bikeCompGridStyle}
                onMoreInfoBtnClick={id => handleMoreInfoBtnClick(id)}
                bikeData={productsData}
                loading={isLoading}
              />
            </Col>
          </Row>

          {/* <Row
            className={'bikeHelperAds'}
            gutter={[{ xs: 12, sm: 16, md: 24 }, 20]}
          >
            <Col span={24}>
              <List
                grid={{
                  gutter: 12,
                  xs: 1,
                  sm: 1,
                  md: 2,
                  lg: 2,
                  xl: 2,
                  xxl: 3,
                }}
                dataSource={[
                  {
                    headerText: 'WELKE FIETS IS PERFECT VOOR JOU?',
                    descriptionText: 'Sportief, functioneel of stijlvol?',
                    buttonText: 'Help mij kiezen',
                    imgBackgroundColor: '#EE3143',
                    buttonTextColor: '#EE3143',
                  },
                  {
                    buttonVisibility: 'false',
                    buttonTextColor: '#EE3143',
                    imgSource: 'icons/HomePage/mBikeLogo.png',
                  },
                  {
                    headerText: 'E-BIKE OF GEEN E-BIKE?',
                    descriptionText: 'That’s the question. ',
                    buttonText: 'Waar moet ik op letten? ',
                    imgBackgroundColor: 'rgba(16, 100, 224, 0.48)',
                    buttonTextColor: '#1064E0',
                  },
                ]}
                renderItem={item => (
                  <List.Item>
                    <ImageButtonComponent
                      width={'373px'}
                      height={'214px'}
                      headerText={item.headerText}
                      descriptionText={item.descriptionText}
                      buttonText={item.buttonText}
                      buttonVisibility={item.buttonVisibility}
                      imgBackgroundColor={item.imgBackgroundColor}
                      imgSrc={item.imgSource}
                      EE3143
                      buttonTextColor={item.buttonTextColor}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row> */}
          {/* <Row
            className={'bikeCategories'}
            style={{ paddingTop: '20px', paddingBottom: '20px' }}
          >
            <Col span={24}>
              <Row type="flex" style={{ backgroundColor: 'white' }}>
                <Col xs={24} sm={24} md={17} lg={17} style={{ minWidth: 270 }}>
                  <img
                    width={'100%'}
                    alt={'Location  are not available'}
                    src={`${appConstants.frontEndEnvURL}/icons/HomePage/fietsenLocation.png`}
                    style={{ minHeight: 435 }}
                  />
                </Col>
                <Col xs={24} sm={24} md={7} lg={7}>
                  <div style={{ backgroundColor: 'white' }}>
                    <List
                      header={'Ontdek onze winkels'}
                      className={'commonHeader locationContainer'}
                      dataSource={[
                        {
                          locationHeader: 'Kerkstraat 21',
                          locationDescription: '2970 Schilde',
                        },
                        {
                          locationHeader: 'Brechtsebaan 2',
                          locationDescription: '2960 Sint-Job-in-’t-Goor',
                        },
                        {
                          locationHeader: 'Brendabaan 706',
                          locationDescription: '2100 Antwerpen',
                        },
                        {
                          locationHeader: 'Stationstraat 34',
                          locationDescription: '2950 Kapellen',
                        },
                      ]}
                      grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 1,
                      }}
                      renderItem={item => (
                        <List.Item>
                          <p style={{ fontWeight: 'bold' }}>
                            {item.locationHeader}
                          </p>
                          <p>{item.locationDescription}</p>
                        </List.Item>
                      )}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </Container>
  )
}
