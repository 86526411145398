import React from 'react';
import Container from '../Common/Container/ContainerComponent'
import { Button } from 'antd'


export default function(props){
//	props.googleAnalytics.pageview(`/thankyou/${props.formType}`);
	return(<Container style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'50px 0 190px 0'}} >
		<h1 style={{color:'black',fontSize:'35px'}}>Aanvraag goed ontvangen</h1>
		<p style={{fontSize:'20px'}}>Bedankt voor je contactopname. Onze expert volgt jouw aanvraag zo snel mogelijk op.</p>
		<a  href='/'><Button style={{borderRadius:20}} size='large'>Terug naar home</Button></a>
	</Container>)
}
