import React, { useEffect } from 'react'
import { Button, Card, List, Modal } from 'antd'
import Meta from 'antd/lib/card/Meta'
import * as appConstants from '../AppConstants'
import { usePath } from 'hookrouter'
import _ from 'lodash'

import ModalForm from '../../Common/ModalForm/ModalForm'

import * as BikeComponentStyle from './BikeComponent.module.scss'
import './BikeComponentStyle.css'

export default function BikeComponent(props) {
  const [modalVisible, setModalVisible] = React.useState(false)
  const [dataSource, setDataSource] = React.useState()
  const currentPath = usePath()
  const uspData = props.bikeData.metadata.slice(0, 3).map(item => {
    return `${item.name.charAt(0).toUpperCase() + item.name.slice(1)}: ${
      item.value
    }`
  })

  useEffect(() => {
    if (props.bikeData.usps.length === 3) {
      if (!props.bikeData.usps.every(el => el !== '')) {
        if (!_.isEqual(uspData, dataSource)) setDataSource(uspData)
      }
    } else {
      if (!_.isEqual(uspData, dataSource)) setDataSource(uspData)
    }
  }, [props.bikeData, uspData, dataSource])

  const handleMoreInfoBtnClick = id => {
    props.onMoreInfoBtnClick(id)
  }

  const handleBookBtnClick = () => {
    setModalVisible(!modalVisible)
  }
  function getProductCategory(selectedBikeData){
    let productCategory
    if (selectedBikeData.categories[0] === 'accessoires') {
      productCategory = appConstants.bikesCategories.accessoires
    } else if (selectedBikeData.categories[0] === 'fietsen') {
      productCategory =
        selectedBikeData.categories[1] === 'e-bikes'
          ? selectedBikeData.categories[1].charAt(0).toUpperCase() +
          '-' +
          selectedBikeData.categories[1].charAt(2).toUpperCase() +
          selectedBikeData.categories[1].slice(3)
          : selectedBikeData.categories[1].charAt(0).toUpperCase() +
          selectedBikeData.categories[1].slice(1)
    }
    return productCategory;
  }

  function formatDescriptionOfBike() {
    return (
      <div>
        <span className={`${BikeComponentStyle.redFontColor}`}>
          €{props.bikeData.price}{' '}
        </span>
        {/* <span
          className={`${BikeComponentStyle.redFontColor} ${
            props.bikeData.availability !== 'op voorraad'
              ? BikeComponentStyle.strikeThrough
              : ''
          }`}
        >
          €{props.bikeData.price}{' '}
        </span>
        <span
          className={
            props.bikeData.availability === 'op voorraad'
              ? BikeComponentStyle.greenFontColor
              : BikeComponentStyle.redFontColor
          }
        >
          {props.bikeData.availability}
        </span> */}
      </div>
    )
  }

  return (
    <div
      style={{ padding: 0 ,cursor:'pointer'}} className={BikeComponentStyle.bikeContainer}>
      <Card
        onClick={()=>{handleMoreInfoBtnClick(props.bikeData._id)}}
        cover={
          <img
            alt={`Image not found`}
            src={
              props.bikeData.categories && props.bikeData.image
                ? `${props.bikeData.image}`
                : `${appConstants.frontEndEnvURL}/noimageplaceholder.png`
            }
            style={{
              padding: 10,
              height: 200,
              objectFit: 'contain',
            }}
          />
        }
      >
        <Meta
          title={props.bikeData.name}
          description={formatDescriptionOfBike()}
        />
      </Card>
      <br />
      <List
        onClick={()=>{handleMoreInfoBtnClick(props.bikeData._id)}}
        className={'bikeComponentList'}
        bordered={false}
        itemLayout="horizontal"
        dataSource={dataSource ? dataSource : props.bikeData.usps}
        renderItem={item => (
          <List.Item>
            <div className={BikeComponentStyle.descriptionWrapper}>
              <div
                style={{ margin: '8px 8px' }}
                className={BikeComponentStyle.dot}
              />
              <p>{item}</p>
            </div>
          </List.Item>
        )}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          paddingBottom: 20,
        }}
      >
        <Button
          size={'large'}
          type="danger"
          onClick={(e) => {handleBookBtnClick();e.stopPropagation()}}
        >
          Bestel nu
        </Button>
        <Button
          className={BikeComponentStyle.moreInfoButton}
          size={'large'}
          onClick={() => handleMoreInfoBtnClick(props.bikeData._id)}
        >
          Meer info
        </Button>
      </div>

      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        closeIcon={false}
        footer={null}
        title="SUPER, hier ga je plezier aan beleven!"
      >
        <ModalForm
          formType={'bestelnu'}
          bikeName={props.bikeData.name}
          bikdId={props.bikeData.id}
          bikeUrl={`${window.location.origin}/products/${getProductCategory(props.bikeData)}/${props.bikeData._id}`}
        />
      </Modal>
    </div>
  )
}
