import React from 'react'
import { Form, Input, Button, Icon, message } from 'antd'
import FormItem from 'antd/lib/form/FormItem'

import * as FietsenMintjensAPI from '../../../api/FietsenMintjensAPI'

import './ModalForm.css'
import TextArea from 'antd/es/input/TextArea'

const ModalForm = props => {

  const { getFieldDecorator } = props.form
  const key = 'updatable'

  const handleSubmit = e => {
    e.preventDefault()

    const form = props.form

    form.validateFields((err, values) => {
      if (!err) {
        message.loading({ content: 'Sending the email...', key })
        FietsenMintjensAPI.submitContactUsEmail(values)
          .then(res => {
            if (res.data) {
              message.success({
                content: 'Successfully Sent!',
                key,
                duration: 2,
              })
              props.setModalVisibility(false)
              window.location = `/thankyou/contactUs`
            }
          })
          .catch(err => console.log('error', err))
      }
    })
  }

  return (
    <Form layout="vertical" onSubmit={e => handleSubmit(e)}>
      <FormItem label="Naam">
        {getFieldDecorator('naam', {
          rules: [
            {
              required: true,
              message: 'Please input your name!',
            },
          ],
        })(<Input  />)}
      </FormItem>
      <FormItem label="E-mailadres">
        {getFieldDecorator('email', {
          rules: [
            { type: 'email', message: 'The input is not valid E-mailadres' },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ],
        })(<Input />)}
      </FormItem>
      <FormItem label="Gemeente">
        {getFieldDecorator('gemeente', {
          rules: [
            {
              required: false,
              message: 'Please input your Gementee!',
            },
          ],
        })(<Input  />)}
      </FormItem>

      <FormItem label="Telefoon/gsm">
        {getFieldDecorator('phoneNumber', {
          rules: [
            {
              required: false,
              message: 'Please fill your phoneNumber',
            },
          ],
        })(<Input />)}
      </FormItem>
      <FormItem label="Uw bericht">
        {getFieldDecorator('bericht', {
          rules: [
            {
              required: false,
              message: 'Please fill your bericht',
            },
          ],
        })(<TextArea  />)}
      </FormItem>
      <Button onClick={(e)=>{handleSubmit(e)}} type="primary" htmlType="submit">
        Verstuur <Icon type="right" />
      </Button>
    </Form>
  )
}

export default Form.create()(ModalForm)
