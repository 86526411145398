import React from 'react'
import { Form, Input, Button, Icon, message } from 'antd'
import FormItem from 'antd/lib/form/FormItem'

import * as FietsenMintjensAPI from '../../../api/FietsenMintjensAPI'
import * as AppConstants from '../../Utilities/AppConstants'
import './ModalForm.css'

const ModalForm = props => {

  const { getFieldDecorator } = props.form
  const key = 'updatable'

  const handleSubmit = e => {
    e.preventDefault()

    const form = props.form

    form.validateFields((err, values) => {
      if (!err) {
        values['bikeName'] = props.bikeName
        values['bikeUrl'] = props.bikeUrl
        values['formType'] = props.formType
        values['bikeId']=props.bikeId
        message.loading({ content: 'Sending the email...', key })

        FietsenMintjensAPI.submitEmail(values)
          .then(res => {
            if (res.data) {
              message.success({
                content: 'Successfully Sent!',
                key,
                duration: 2,
              })
              window.location = `/thankyou/${props.formType.toLowerCase()}`
            }
          })
          .catch(err => console.log('error', err))
      }
    })
  }

  return (
    <Form layout="vertical" onSubmit={e => handleSubmit(e)}>
      <p>
        {props.formType === 'bestelnu'
          ? 'Verstuur je gegevens en een Fietsen Mintjens “Expert” neemt spoedig contact met je op!'
          : 'Interesse in deze fiets? Test hem volledig vrijblijvend uit en ontvang persoonlijk advies van onze expert. Reserveer je proefrit!'}
      </p>
      <FormItem label="Jouw naam">
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
              message: 'Please input your name!',
            },
          ],
        })(<Input placeholder="Name" />)}
      </FormItem>
      <FormItem label="Telefoonnummer">
        {getFieldDecorator('phoneNumber', {
          rules: [
            {
              required: true,
              message: 'Please input your phone number!',
            },
          ],
        })(<Input placeholder="Phone Number" />)}
      </FormItem>
      <FormItem label="Jouw email">
        {getFieldDecorator('email', {
          rules: [
            { type: 'email', message: 'The input is not valid E-mail' },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ],
        })(<Input placeholder="Email" />)}
      </FormItem>
      <FormItem label="Adres">
        {getFieldDecorator('address', {
          rules: [
            {
              required: true,
              message: 'Please fill your address',
            },
          ],
        })(<Input placeholder="Straatnaam + nummer" />)}
      </FormItem>
      <FormItem label="Post Code">
        {getFieldDecorator('postcode', {
          rules: [
            {
              required: true,
              message: 'Please fill your postcode',
            },
          ],
        })(<Input type={'number'} placeholder="Post Code" />)}
      </FormItem>
      <Button onClick={(e)=>{handleSubmit(e)}} type="primary" htmlType="submit">
        Verstuur <Icon type="right" />
      </Button>
    </Form>
  )
}

export default Form.create()(ModalForm)
