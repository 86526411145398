import React from 'react'
import Home from './Components/Home/Home'
import SearchResult from './Components/SearchResult'
import ProductInformation from './Components/ProductInformation/ProductInformation'
import Product from './Components/Product/Product'
import * as AppConstants from './Components/Utilities/AppConstants'
import { bikesCategories } from './Components/Utilities/AppConstants'
import ReactGA from 'react-ga';
import ThankYou from './Components/ThankYou/ThankYou'
/*ReactGA.initialize('-'); //UA-164521515-1*/

const routes = {
  '/': () => <Home />,
  '/products/search': () => <SearchResult />,
  '/products/:category': ({ category }) => {
    //googleAnalytics={ReactGA}
    let bikeCategory = Object.values(AppConstants.bikesCategories).filter(bikesCategory=>bikesCategory.id.toLowerCase() == category.toLowerCase())
    return bikeCategory.length > 0 ? <Product  productCategory={bikeCategory[0]} /> : false
  },
  // '/product/:id': ({ id }) => <ProductInformation productId={id} />,
  '/products/:category/:id': ({ category, id }) => {
    //googleAnalytics={ReactGA}
    let bikeCategory = Object.values(AppConstants.bikesCategories).filter(bikesCategory=>bikesCategory.id.toLowerCase() == category.toLowerCase())
    return  bikeCategory.length > 0 ? <ProductInformation  productId={id} productCategory={category} /> : false
  },
  '/thankyou/:formType':({formType})=> {

    const isAvailable = AppConstants.formTypes.filter((formTypeApp)=>formTypeApp === formType);
    if(formType == 'contactUs')
    {
      isAvailable.push(true)
    }
    if(isAvailable.length === 0)
    {
      return;
    }
    return <ThankYou formType={formType} /> //googleAnalytics={ReactGA}
  }
}
export default routes
